<template>
  <div
    id="dropdown-wrapper"
    class="d-flex flex-row justify-content-end"
    @focusout="hideDropdown"
  >
    <button
      id="navbar-dropdown-btn"
      aria-controls="dropdown-options-block"
      :aria-expanded="modelValue"
      :aria-disabled="mobileView.toString()"
      class="greeting-btn pe-2 pe-md-3"
      @click="toggleVisibility"
      @keydown.space.exact.prevent="toggleVisibility"
      @keydown.shift.tab="hideDropdown"
    >
      <img
        id="person-svg"
        class="d-none d-md-block"
        :src="require('~/img/User_Icon.svg')"
        alt=""
      >
      <p
        class="greeting-text m-0"
      >
        Hello, {{ firstName }}!
      </p>
      <img
        id="chevron-svg"
        class="d-none d-md-block"
        :src="require('~/img/AccordionDownBlack.svg')"
        alt=""
      >
    </button>

    <transition name="dropdown-fade">
      <div
        id="dropdown-options-block"
        ref="dropdown"
        :class="['me-2', { 'd-none': !modelValue }]"
      >
        <div class="dropdown-head d-flex">
          <img
            class="dropdown-icon"
            :src="require('~/img/User_Icon.svg')"
            alt=""
          >
          <div class="header-text">
            {{ firstName }} {{ lastName }}
          </div>
        </div>
        <slot />
        <div class="dropdown-tail" />
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  props: {
    firstName: {
      type: String,
      default: ''
    },
    lastName: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      mobileView: false
    }
  },
  created() {
    this.handleView()
    window.addEventListener('resize', this.handleView)
  },
  methods: {
    toggleVisibility() {
      this.$emit('update:modelValue', !this.modelValue)
    },
    hideDropdown(event) {
      if(!event.currentTarget.contains(event.relatedTarget))
      {
        this.$emit('update:modelValue', false)
      }
    },
    handleView() {
      this.mobileView = window.innerWidth <= 768
    }
  }
}

</script>

<style scoped>

#dropdown-wrapper {
  font-family: 'Proxima Nova', sans-serif;
}

#dropdown-options-block {
  top: 2.65em;
  box-shadow: 0 0 5px 5px #eaeaea;
  border-radius: .5em;
  position: absolute;
  font-size: 1.125em;
  font-family: 'Goldplay', sans-serif;
  z-index: 9001;
  background-color: white;
}

.dropdown-head {
  padding: 1.33em;
  border-bottom: solid;
  border-width: 1px;
  border-color: #737373;
  vertical-align: top;
}

.header-text {
  margin-left: 0.89em;
  color: black;
  position: revert;
}

.dropdown-tail {
  height: 1em;
}

#person-svg {
  width: 24px;
  height: 24px;
  padding-right: .3em;
}

.dropdown-icon {
  height: 28px;
  width: 28px;
  vertical-align: middle;
}

.greeting-btn {
  display: inline-flex;
  align-items: center;
  border: none;
  background-color: white;
  cursor: pointer;
  font-family: revert;
  height: fit-content;
}

#chevron-svg {
  width: .3em;
  height: .45em;
  padding-left: .6em;
  box-sizing: content-box;
}

.dropdown-fade-enter-active, .dropdown-fade-leave-active {
  transition: all .1s ease-in-out;
}
.dropdown-fade-enter, .dropdown-fade-leave-to {
  opacity: 0;
  transform: translateY(-.75em);
}

@media (max-width: 768px) {
  #dropdown-options-block {
    display: none;
  }
  #navbar-dropdown-btn {
    cursor: default;
  }
}
</style>

