<template>
  <div>
    <a
      :href="url"
      class="d-flex dropdown-option d-md-none text-decoration-none"
    >
      <img
        v-if="imgPath == sharedLogout"
        class="dropdown-icon"
        :src="require('~/img/logout.svg')"
        alt=""
      >
      <img
        v-else-if="imgPath"
        class="dropdown-icon"
        :src="imgPath"
        alt=""
      >
      <div class="option-text">{{ label }}</div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      Type: String,
      required: true
    },
    imgPath: {
      Type: String,
      required: true
    },
    label: {
      Type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.option-text {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 1em;
  color: black;
  text-decoration: none;
}

.dropdown-option {
  font-size: 1.25em;
  padding: 1rem;
  padding-left: 1.375em;
  min-width: 18.5em;
  min-height: 1em;
  background-color: #ffffff;
  border-bottom: 1px solid #D9D9D9;
}

.dropdown-option:hover {
  background-color: #eef2f8;
}

.dropdown-icon {
  height: 1.75em;
  vertical-align: middle;
}

</style>