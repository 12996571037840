
import NavMenu from '../NavMenu/NavMenu.vue'
import NavTopDropdown from './NavTopDropdown.vue'
import MenuItem from './MenuItem.vue'
import MobileMenuItem from './MobileMenuItem.vue'
import { onMounted, ref, watch } from 'vue'

interface DropOption {
  url: string;
  imgPath: string;
  label: string;
}

export default {
  components: {
    NavMenu,
    NavTopDropdown,
    MenuItem,
    MobileMenuItem
  },
  props: {
    signInUrl: {
      type: String,
      default: 'https://www.barbri.com'
    },
    firstName: {
      type: String,
      default: ''
    },
    lastName: {
      type: String,
      default: ''
    },
    logOutUrl: {
      type: String,
      default: ''
    },
    bannerWidth: {
      type: String,
      default: ''
    },
    dropOptions: {
      type: Array as () => Array<DropOption>,
      default: () => {
        return []
      }
    }
  },
  setup(props: any) {
    const showMenu = ref(false)
    const showDropdown = ref(false)
    const isLoggedIn = ref(false)

    let exploreHovered = false

    onMounted(() => { isLoggedIn.value = !!props.firstName })

    const toggleShowMenu = () => {
      showMenu.value = !showMenu.value
      document.body.style.overflow = showMenu.value ? 'hidden' : ''
    }

    const closeMenu = () => {
      showMenu.value = false
      document.body.style.overflow =  ''
    }

    //After 400ms, if exploreMouseOut hasn't fired yet, shows the menu if it's not already shown.
    const exploreHover = () => {
      exploreHovered = true
      setTimeout(() => {
        if (exploreHovered && !showMenu.value) {
          toggleShowMenu()
        }
      }, 400)
    }

    const exploreMouseOut = () => {
      exploreHovered = false
    }

    watch(showMenu, () => {
      showDropdown.value = showDropdown.value && !showMenu.value
    })

    return {
      showMenu,
      showDropdown,
      isLoggedIn,
      toggleShowMenu,
      closeMenu,
      exploreHover,
      exploreMouseOut
    }
  }
}
