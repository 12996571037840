import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68471288"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "mega-header d-flex d-md-none" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "header-text" }
const _hoisted_5 = {
  id: "static-banner",
  class: "d-none d-md-flex justify-content-between align-items-center"
}
const _hoisted_6 = { class: "d-flex align-items-center" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "d-flex align-items-center" }
const _hoisted_9 = ["src"]
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "link-sections" }
const _hoisted_12 = { class: "col-header" }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "link-list" }
const _hoisted_15 = ["href"]
const _hoisted_16 = {
  key: 0,
  class: "menu-spacer d-block d-md-none"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.menuItem.htmlId,
    class: "mega-body"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "d-inline btn back-arrow",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeMenu && _ctx.closeMenu(...args)))
      }, [
        _createElementVNode("img", {
          src: require('~/img/AccordionDownBlack.svg'),
          alt: "back"
        }, null, 8, _hoisted_3)
      ]),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.menuItem.title), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", _hoisted_6, [
        _createElementVNode("img", {
          src: _ctx.getImageUrl(_ctx.menuItem.headerIcon),
          class: "header-svg",
          alt: ""
        }, null, 8, _hoisted_7),
        _createTextVNode(" " + _toDisplayString(_ctx.menuItem.header), 1)
      ]),
      _createElementVNode("span", _hoisted_8, [
        _createElementVNode("img", {
          src: require('~/img/BARBRI_Logo.svg'),
          alt: ""
        }, null, 8, _hoisted_9),
        _createElementVNode("button", {
          class: "ms-3 py-0 header-btn",
          "aria-controls": "nav-menu-container",
          "aria-expanded": "true",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeMega && _ctx.closeMega(...args)))
        }, [
          _createElementVNode("img", {
            src: require('~/img/XCircleIcon.svg'),
            alt: "close mega menu"
          }, null, 8, _hoisted_10)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItem.sections, (section) => {
        return (_openBlock(), _createElementBlock("div", {
          key: section.title,
          class: "link-col"
        }, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("img", {
              class: "circled-header-svg d-inline d-md-none",
              src: _ctx.getImageUrl(section.titleIcon),
              alt: ""
            }, null, 8, _hoisted_13),
            _createTextVNode(" " + _toDisplayString(section.title), 1)
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.links, (link) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: link.label
                }, [
                  _createElementVNode("a", {
                    class: "mega-link",
                    href: link.url
                  }, _toDisplayString(link.label), 9, _hoisted_15)
                ]))
              }), 128))
            ])
          ])
        ]))
      }), 128)),
      (_ctx.isLoggedIn)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16))
        : _createCommentVNode("", true)
    ])
  ], 8, _hoisted_1))
}