
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    isLoggedIn: {
      type: Boolean,
      default: false
    },
    menuItem: {
      type: Object,
      required: true
    }
  },
  emits: ['closeMega', 'closeMenu'],
  data() {
    return {
      imageLookup: new Map()
    }
  },
  mounted() {
    // @ts-ignore
    const images = require.context('~/img/', true, /\.svg$/)

    images.keys().forEach(key => (this.imageLookup.set(key.split(/(\\|\/)/g).pop(), { pathLong: images(key) })))
  },
  methods: {
    closeMega() {
      this.$emit('closeMega')
    },
    closeMenu() {
      this.$emit('closeMenu')
    },
    getImageUrl(name: string) {
      return this.imageLookup.get(name)?.pathLong ?? ''
    }
  }
})
