<template>
  <div
    id="nav-menu-container" 
    @mouseleave="closeMega"
  >
    <div class="nav-menu shadow">
      <div class="nav-menu-header d-flex d-md-none">
        <button
          id="close-btn"
          class="ps-3 ps-md-4"
          type="button" 
          aria-label="Close"
          @click="closeMega"
        >
          <div class="float-start">
            ⨉
          </div>
          <img
            :src="require('~/img/BARBRI_Logo.svg')"
            class="logo align-baseline"
            alt=""
          >
        </button>
      </div>
      <slot name="mobile-dropdown-items" />
      <div
        id="explore"
        class="d-none d-md-block ps-4"
      >
        Explore BARBRI
      </div>
      <div
        id="mega-menu-arrows"
        :class="menuNumber != 0 ? 'd-none d-md-block' : ''"
      >
        <div
          v-for="menu in menus"
          :key="menu.value"
        >
          <button
            class="mega-menu-btn p-3 px-md-4"
            :class="{'mega-active': menuNumber == menu.value, 'mega-inactive': menuNumber != menu.value}"
            :aria-controls="menu.menuId"
            :aria-expanded="menuNumber == menu.value"
            @click="onMenuOptionClicked(menu.value)"
            @mouseover="onMenuOptionHovered(menu.value)"
          >
            {{ menu.name }}
            <img
              :src="require('~/img/arrow-pointing-right.svg')"
              class="arrow-svg my-auto"
              alt=""
            >
          </button>
        </div>
      </div>
      <div class="menu-footer d-none d-md-block" />
    </div>
    <div class="bottom-0 position-absolute w-100 logout-container">
      <slot name="logout" />
    </div>
    <div
      v-for="menu in menus"
      :key="menu.value"
    >
      <MenuItem
        v-show="menuNumber == menu.value"
        :menu-item="menu.menuItem"
        :is-logged-in="isLoggedIn"
        @close-mega="closeMega"
        @close-menu="closeMenu"
      />
    </div>
    <div
      class="backdrop d-none d-md-block"
      @click="closeMega"
    />
  </div>
</template>

<script>
import { NavMenu } from './enums.ts'
import { ref } from 'vue'
import MenuItem from '../MegaMenuItems/MegaMenuItem.vue'
import StudentMenuItem from '../MegaMenuItems/StudentMenu.json'
import SchoolMenuItem from '../MegaMenuItems/SchoolMenu.json'
import ProfessionalMenuItem from '../MegaMenuItems/ProfessionalMenu.json'
import LawFirmMenuItem from '../MegaMenuItems/LawFirmMenu.json'
const barbriLogoImg = new Image()
barbriLogoImg.src = require('~/img/BARBRI_Logo.svg')
const arrowRightImg = new Image()
arrowRightImg.src = require('~/img/arrow-pointing-right.svg')

export default {
  components: {MenuItem},
  props: {
    isLoggedIn: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close'],
  setup(_, { emit }) {
    let menuNumber = ref(0)
    const menus = [
      { name: 'For Students', menuId: 'student-mega-menu', value: NavMenu.StudentMenu, menuItem: StudentMenuItem },
      { name: 'For Law Schools & Universities', menuId: 'school-mega-menu', value: NavMenu.SchoolMenu, menuItem: SchoolMenuItem },
      { name: 'For Professionals', menuId: 'professional-mega-menu', value: NavMenu.ProfessionalMenu, menuItem: ProfessionalMenuItem },
      { name: 'For Law Firms & Organizations', menuId: 'law-firm-mega-menu', value: NavMenu.LawFirmMenu, menuItem: LawFirmMenuItem }
    ]

    const onMenuOptionClicked = (menuOption) => {
      if (menuNumber.value == menuOption) {
        menuNumber.value = 0
      } else {
        menuNumber.value = menuOption
      }
    }

    const onMenuOptionHovered = (menuOption) => {
      if (window.innerWidth > 768) {
        menuNumber.value = menuOption
      }
    }

    const closeMega = () => {
      closeMenu()
      emit('close')
    }

    const closeMenu = () => {
      menuNumber.value = 0
    }

    return {
      NavMenu,
      menuNumber,
      menus,
      onMenuOptionClicked,
      onMenuOptionHovered,
      closeMega,
      closeMenu
    }
  }
}
</script>

<style scoped>
.nav-menu {
  position: fixed;
  right: auto;
  display: flex;
  float: left;
  flex-direction: column;    
  background-color: white;
  width: 408px;
  max-width: 100%;
  margin: 0;
  outline: 0;
  z-index: 9002;
  transform: translateX(0);
  font-family: 'Goldplay', sans-serif;
  overflow-y: auto;
  top: 50px;
  left: 67px;
  border-radius: 0 0 10px 10px;
}

#nav-menu-container {
  display: flex;
  width: 100vw;
}
.button {
  cursor: pointer;
}

.nav-menu-header {
  width: 100%;
  overflow-y: auto;
  display: none;
  flex-direction: column;
}
.backdrop {
  position: fixed;
  top: 50px;
  left: -50vw;
  height: 100vh;
  width: 200vw;
  background-color: #343a40!important;
  opacity: .6;
  z-index: 9001;
}

#explore {
  padding: 21px;
  font-size: 1.25em;
  font-family: 'Goldplay', sans-serif;
  background-color: #DBE9F8;
  color: #101010;
  padding-left: 32px;
}

.mega-menu-btn {
  font-family: 'Goldplay', sans-serif;
  font-weight: 100;
  width: 100%;
  font-size: 1.25em !important;
  display: flex;
  justify-content: space-between;
  background-color: white;
  border: none;
  border-bottom: 1px solid #C9C9C9;
  color: black;
  cursor: pointer;
  border-bottom: 1px solid #D9D9D9;
}

.mega-active  {
  background-color: #f3f7fb;
  font-weight: normal;
}

.mega-menu-btn:hover {
  background-color: #f3f7fb;
  font-weight: normal;
}

.menu-footer {
  width: 100%;
  height: 12px;
}

.arrow-svg {
  height: 1.25em;
}

.logo {
  height: 18px;
  margin-left: -1em;
}

#close-btn {
  justify-content: space-between;
  background-color: white;
  color: #1a8cdd;
  border: none;
  height: 2.58em;
  font-size: 1.5em;
  cursor: pointer;
  vertical-align: baseline;
  font-family: revert;
  line-height: 1;
}

@media (max-width: 768px) {
  .nav-menu {
    position: absolute;
    display: block;
    min-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 0;
  }
  .nav-menu-header {
    border-bottom: 1px solid #D9D9D9;
    height: 48px;
    min-height: 3rem;
  }
  .logout-container {
    border-top: 1px solid #D9D9D9;
    z-index: 9004;
    left: 0px;
  }
  .mega-menu-btn {
    font-size: unset !important;
  }
  .arrow-svg {
    height: 0.8em;
  }
}

</style>