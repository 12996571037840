<template>
  <div>
    <a
      :href="url"
      class="d-flex dropdown-option"
    >
      <img
        v-if="imgPath == sharedLogout"
        class="dropdown-icon"
        :src="require('~/img/logout.svg')"
        alt=""
      >
      <img
        v-else-if="imgPath"
        class="dropdown-icon"
        :src="imgPath"
        alt=""
      >
      <p class="option-text mb-0">{{ label }}</p>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      Type: String,
      required: true
    },
    imgPath: {
      Type: String,
      required: true
    },
    label: {
      Type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.option-text {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  margin-left: 1em;
  color: black;
  padding-top: .15em
}

.dropdown-option {
  padding: 1.375em;
  min-width: 18.5em;
  min-height: 1em;
  border-bottom: solid;
  border-width: 1px;
  border-color: #737373;
  display: block;
  color: black;
  text-decoration: none !important;
}

.dropdown-option:hover {
  background-color: #eef2f8;
}

.dropdown-icon {
  height: 1.75em;
  vertical-align: middle;
}

</style>